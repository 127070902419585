<template>
    <div class="enterprise">
        <div class="row align-items-center" style="height: 350px;">
            <div class="col-12 col-md-8 h-100">
                <div  class="d-flex h-100 enterprise-text-section flex-column justify-content-center"  @mousemove="updateTextGradient" style="gap:20px">
                    <div class="enterprise-title gradientText" >
                        Boost your business with Quizell's enterprise
                    </div>
                    <div class="enterprise-description gradientText" >
                        Increase the growth of your business with user-friendly personalization quizzes while enjoying
                        VIP customer service.
                    </div>
                    <div class="enterprise-button mt-4">
                        <a href="https://quizell.com/pages/enterprise" target="_blank" class="button">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="enterprise-features">
                    <div class="feature my-2 d-flex" v-for="feature in features" :key="feature">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="12" fill="#553E6A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" fill="#B46DFB"/>
</svg>
<div class="">{{feature}}</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return {
            features : [
  "Custom Engagements",
  "Unlimited Quiz",
  "Unlimited Features",
  "Unlimited Products",
  "Custom Integrations",
  "Custom Features",
  "Dedicate customer success",
  "Faster Quiz Performance"
]
        }
    },
    methods:{
        updateTextGradient(event) {
      let x = event.clientX / window.innerWidth;
      let y = event.clientY / window.innerHeight;
      let deg = Math.atan2(y - 0.5, x - 0.5) * (180 / Math.PI) + 90;
      let gradientText = document.querySelectorAll('.gradientText')
      gradientText.forEach((element) => {
        element.style.color = `transparent`;
        element.style.background = `linear-gradient(${-deg}deg, #FFFFFF 0%, #71717A 87.39%)`;
        element.style.webkitBackgroundClip = `text`;
        element.style.backgroundClip = `text`;
      });
    }
    }
}
</script>
<style scoped>
.gradientText{
    background: linear-gradient(-113.277deg, rgb(255, 255, 255) 0%, rgb(113, 113, 122) 87.39%) text;
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
.enterprise {
    background: radial-gradient(75.78% 69.58% at 50% 0%, rgb(77 38 114) 0%, rgb(26 23 30) 100%);
    border-radius: 32px;
    padding: 25px;
    min-height: 350px;
}
.enterprise-text-section {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.enterprise-title, .enterprise-description {
  color: transparent;
  display: inline-block;
}
.enterprise-title {
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: transparent
}

.enterprise-description {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    width: 75%;
}
.enterprise-button .button{

padding: 12px 24px 12px 24px;
gap: 8px;
border-radius: 99px;
background: rgba(127, 86, 217, 1);
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: center;
color: rgba(255, 255, 255, 1);
text-decoration:none;
}
.enterprise-features{

padding: 35px 27px 35px 27px;
border-radius: 24px;
background: rgba(255, 255, 255, 0.08);

}
.enterprise-features .feature{
    font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 24px;
color:#FFFFFF;
gap:10px;

}
</style>